import React from 'react';
import {
	ActionsIcon,
	EmptyListIcon,
	IconProps,
	UnknownIcon,
} from '@sharefiledev/flow-web';
import { Avatar } from 'antd';
import { ResourceType } from '../../data/clients';
import { PlayIcon } from '../icons';
import { useFetchIconByName } from './useFetchIconByName';

export enum IconType {
	EmptyStateIcon = 'emptyStateIcon',
}

export type ResourceIconProps = IconProps & {
	resourceType: ResourceType;
	iconTag?: string;
	iconType?: IconType;
};

export function ResourceIcon(props: ResourceIconProps) {
	const { data: icons } = useFetchIconByName(props.iconTag);

	return props.iconType === IconType.EmptyStateIcon && !!icons?.emptyStateIcon ? (
		<icons.emptyStateIcon {...props} />
	) : !!icons?.defaultIcon ? (
		<icons.defaultIcon {...props} />
	) : props.resourceType === ResourceType.Action ? (
		<ActionsIcon {...props} />
	) : props.resourceType === ResourceType.Trigger ? (
		<PlayIcon {...props} />
	) : props.iconTag?.match(/Initials\?(\w+)$/) ? (
		<Avatar size={24} style={{ flexShrink: 0 }}>
			{props.iconTag.split('?')[1]}
		</Avatar>
	) : props.iconType === IconType.EmptyStateIcon ? (
		<EmptyListIcon {...props} />
	) : (
		<UnknownIcon {...props} />
	);
}
