import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { LaunchFlowAction } from '../launch/actions';
import { checkRolesAndPreferences } from '../provisioning';
import { LaunchFlowExtensionProps } from './LaunchFlowExtension';
import { ContextualActionComponent } from './types';

export type ContextualExtension = {
	extensionName: string;
	actionComponent: ContextualActionComponent;
	actionName: string;
	triggerName: string;
	isEnabled: (app: PiletApi) => boolean;
};

const launchFlowExtensions: ContextualExtension[] = [
	{
		actionName: 'Quick start',
		extensionName: 'urn:sfextslot:projects-pilet:create',
		actionComponent: LaunchFlowAction,
		triggerName: 'ShareFile/sharefile/manualStartOnProject',
		isEnabled: app =>
			checkRolesAndPreferences(app, {
				requiredRoles: ['Employee', 'CanSendDocumentsForSignature'],
				requiredASTs: ['EnableAutomatedWorkflows'],
			}),
	},
	/*{
		actionName: 'Start workflow',
		extensionName: 'sharefile_ui-prospective_client-context_action',
		actionComponent: LaunchFlowAction,
		triggerName: 'manualStartOnProspect',
		isEnabled: app =>
			checkRolesAndPreferences(app, {
				requiredRoles: ['Employee', 'CanSendDocumentsForSignature'],
				requiredASTs: ['EnableAutomatedWorkflows'],
			}),
	},*/
];

const LazyLaunchFlowExtension = React.lazy(() => import('./LaunchFlowExtension'));

const AsyncLaunchFlowExtension: React.FC<LaunchFlowExtensionProps> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyLaunchFlowExtension {...props} />
		</React.Suspense>
	);
};

export function configureLaunchFlowExtensions(app: PiletApi) {
	const { user, account } = app.sf.piletUserContext.get();

	launchFlowExtensions.forEach(
		({ extensionName, actionComponent, triggerName, actionName, isEnabled }) =>
			app.registerExtension(extensionName, props => {
				return (
					<AsyncLaunchFlowExtension
						piletApi={app}
						user={user}
						account={account}
						actionComponent={actionComponent}
						triggerName={triggerName}
						actionName={actionName}
						isEnabled={isEnabled}
						{...props}
					/>
				);
			})
	);
}
