import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Button, fromThemeColors, styleUtils, ThemeColor } from '@sharefiledev/flow-web';

export const StartWorkflowButton = styled(Button.Primary)`
	margin: 0px 0px 15px 0px;
	padding-right: 10px;

	div {
		line-height: 10px;
	}
`;

export const WorkflowOption = styled.div`
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const FloatingAction = css`
	display: inline-flex;
	z-index: 1;
	margin-right: 8px;
`;

export const ButtonIcon = css`
	display: inline-block;
	transition: 0.5s ease-in-out;
`;

export const FloatingActionButton = css`
	height: 40px;
	z-index: 2;
	min-width: 40px;
	max-width: 40px;
	-webkit-transition: max-width 0.5s;
	:focus > :first-child {
		box-shadow: inset ${styleUtils.shadow1.boxShadow};
	}
	:hover {
		max-width: 14rem;
	}
`;

export const FloatingActionWrapper = styled.div`
	background-color: ${fromThemeColors(ThemeColor.action)};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 20px;
	height: 40px;
	margin-left: 2px;
	line-height: 28px;
	color: white;
	gap: 8px;
	padding: 0px 11px 0px 11px;
	:hover {
		background-color: ${fromThemeColors(ThemeColor.actionHover)};
		#startWorkflowIcon {
			transform: rotate(180deg);
		}
	}
`;
