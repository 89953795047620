import React from 'react';
import { MessageContainer, MessageIconContainer } from './SmallMessageBanner.styled';

export type SmallMessageBannerProps = {
	message: string | JSX.Element;
	background: string;
	iconElement: JSX.Element; // distinct from `icon` being used in certain places, which requires a "component"
};

export function SmallMessageBanner(props: SmallMessageBannerProps) {
	return (
		<MessageContainer background={props.background}>
			<MessageIconContainer>{props.iconElement}</MessageIconContainer>
			<span>{props.message}</span>
		</MessageContainer>
	);
}
