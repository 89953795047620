import React from 'react';
import { LoadingIconPrimary, Toggle as FlowWebToggle } from '@sharefiledev/flow-web';
import { ToggleContainer } from './Toggle.styled';

export interface ToggleProps {
	analyticsId?: string;
	id?: string;
	on: boolean;
	disabled?: boolean;
	onToggle(): void;
	active?: boolean;
}

export function Toggle(props: ToggleProps) {
	return (
		<ToggleContainer>
			<FlowWebToggle
				data-analytics-id={props.analyticsId}
				id={props.id}
				on={props.on}
				onToggle={props.onToggle}
				disabled={props.disabled || props.active}
			/>
			{props.active && <LoadingIconPrimary />}
		</ToggleContainer>
	);
}
