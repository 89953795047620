import UrlPattern from 'url-pattern';
const basePath = '/workflows/dashboard';
export const BaseRoutePattern = new UrlPattern(basePath);

// Show WorkflowList + Add button on Dashboard
export const workflowListPath = basePath + '/flows';
export const FlowListRoutePattern = new UrlPattern(workflowListPath);

// Show Designer + WorkflowList + Add button on Dashboard
export const designExistingFlowPath = workflowListPath + '/:id';
export const designNewFlowPath = workflowListPath + '/new';

export interface SelectedFlowRouteParams {
	id: string;
}

export const FlowDesignerRoutePattern = new UrlPattern(designExistingFlowPath);
export const buildFlowDesignerRoute = (params: SelectedFlowRouteParams) => {
	return FlowDesignerRoutePattern.stringify(params);
};

// Show FlowOptions + WorkflowList + Add button on Dashboard
export const flowStartPath = designExistingFlowPath + '/start';
export const FlowStartRoutePattern = new UrlPattern(flowStartPath);
export const buildFlowStartRoute = (params: SelectedFlowRouteParams) => {
	return FlowStartRoutePattern.stringify(params);
};

// Show ActivityList on Dashboard
export const flowActivityListPath = workflowListPath + '/:id/activity'; // show ActivityList for a given flow id
export const FlowActiviyListRoutePattern = new UrlPattern(flowActivityListPath);

export const flowActivityListRunLogsPath = `${flowActivityListPath}/:runId/logs`;
export const FlowActivityListRunLogsRoutePattern = new UrlPattern(
	flowActivityListRunLogsPath
);

export const allActivityFlow = 'all';
export const allActivityListPath = `${workflowListPath}/${allActivityFlow}/activity`; // show ActivityList for all flows
export const AllActivityListRoutePattern = new UrlPattern(allActivityListPath);

export const allActivityListRunLogsPath = `${allActivityListPath}/:runId/logs`;
export const AllActivityListRunLogsRoutePattern = new UrlPattern(
	allActivityListRunLogsPath
);

export const buildFlowActivityRoute = (params: SelectedFlowRouteParams) => {
	return FlowActiviyListRoutePattern.stringify(params);
};

export interface RunLogsRouteParams {
	flowId?: string;
	runId: string;
}

export const buildRunLogsRoute = (params: RunLogsRouteParams) => {
	return FlowActivityListRunLogsRoutePattern.stringify({
		id: params.flowId ?? allActivityFlow,
		runId: params.runId,
	});
};

export const workflowListPaths = [
	workflowListPath,
	designExistingFlowPath,
	designNewFlowPath,
	flowStartPath,
];
export const designerPaths = [designExistingFlowPath, designNewFlowPath];
export const activityListPaths = [
	allActivityListPath,
	allActivityListRunLogsPath,
	flowActivityListPath,
	flowActivityListRunLogsPath,
];
export const findMatchingPath = (pathsList: string[], pathname: string) => {
	return pathsList.find(path => {
		return !!new UrlPattern(path).match(pathname);
	});
};
