import React, { lazy, Suspense } from 'react';
import { OverlayModalComponentProps } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { Props as StorageFileExportModalProps } from '../FileExport/components/StorageFileExportModal';
import { LaunchFlowOverlayParams, workflowsPiletOverlays } from '../sdk';

export * from './getDefaultOverlayOptions';

export type LaunchFlowOverlayProps = LaunchFlowOverlayParams &
	Pick<
		OverlayModalComponentProps<typeof workflowsPiletOverlays.launchFlow>,
		'completeInteraction' | 'cancelInteraction'
	>;

const LazyLaunchFlowOverlayContent = lazy(() => import('./LaunchFlowOverlayContent'));
export const LaunchFlowOverlay = (props: LaunchFlowOverlayProps) => (
	<Suspense fallback={<>loading...</>}>
		<LazyLaunchFlowOverlayContent {...props} />
	</Suspense>
);

const LazyStorageFileExportModal = lazy(
	() => import('../FileExport/components/StorageFileExportModal')
);
export const AsyncStorageFileExportModal: React.FC<
	StorageFileExportModalProps
> = props => (
	<Suspense fallback={<Spin />}>
		<LazyStorageFileExportModal {...props} />
	</Suspense>
);
