import React, { useContext } from 'react';
import { Account, User } from '@citrite/sf-api';
import { ResourceRegistry } from './data/clients/resourceRegistryClient';

export interface WorkflowsUIContext {
	account?: Account;
	user?: User;

	resourceRegistryClient?: ResourceRegistry;

	searchQuery?: string;
	currentTab?: string;
}

const WorkflowsContext = React.createContext<WorkflowsUIContext>({});

export const WorkflowsContextProvider = WorkflowsContext.Provider;

export const useWorkflowsContext = () => {
	const workflowsUiContext = useContext(WorkflowsContext);
	return workflowsUiContext;
};

export default WorkflowsContextProvider;
