import {
	OverlayDrawerOptions,
	OverlayModalOptions,
} from '@sharefiledev/sharefile-appshell';

export type DefaultModalConfiguration = {
	isFullscreen?: boolean;
	titleText?: string;
};

export type DefaultDrawerConfiguration = {
	titleText?: string;
};

export function getDefaultModalProps(
	props: DefaultModalConfiguration
): Partial<OverlayModalOptions<any>> {
	return {
		modalProps: {
			title: props.titleText,
			width: '95vw',
			footer: null,
			centered: true,
			...(props.isFullscreen && {
				styles: {
					body: {
						height: 'calc(95vh - 86px)',
						margin: '20px 0px',
						overflowY: 'auto',
					},
				},
			}),
		},
	};
}

export function getDefaultDrawerProps(
	props: DefaultDrawerConfiguration
): Partial<OverlayDrawerOptions<any>> {
	return {
		drawerProps: {
			title: props.titleText,
			placement: 'right',
			size: 'large',
		},
	};
}

export type SubDrawerConfiguration = DefaultDrawerConfiguration & {
	open: boolean;
	onClose: () => void;
};

export function getDefaultSubDrawerProps({
	titleText,
	open,
	onClose,
}: SubDrawerConfiguration): Partial<OverlayDrawerOptions<any>> {
	return {
		drawerProps: {
			title: titleText,
			placement: 'right',
			size: 'large',
			open,
			onClose,
		},
	};
}
