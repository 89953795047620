import React from 'react';
import { projectsExtensionSlots } from '@sharefiledev/projects-pilet';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { shouldDisplayIntegrations } from '../checkIntegrationsAST';
import { ExtensionType } from '../types';
import { AsyncFileExportExtension } from '.';

export const configureFileExportProjectsExtension = (app: PiletApi) => {
	const { account } = app.sf.piletUserContext.get();
	if (shouldDisplayIntegrations(app)) {
		app.registerExtension(projectsExtensionSlots.fileExportButton, ({ params }) => {
			// TODO: remap at the projects level
			const files = params.files.map(file => ({
				name: file.fileName,
				rid: file.shareFileStreamId,
			}));

			return (
				<AsyncFileExportExtension
					account={account}
					files={files}
					extensionType={ExtensionType.PROJECTS}
				/>
			);
		});
	}
};
