export function getFromStorage(dataStore: Storage, key: string) {
	const data = dataStore.getItem(key);
	let obj: any;

	if (data !== null) {
		try {
			obj = JSON.parse(data);
		} catch (e) {
			obj = data;
		}
	}

	return obj;
}
