import React from 'react';

export type WindowSize = {
	width: number;
	height: number;
};

function getWindowSize(): WindowSize {
	const { clientWidth: width, clientHeight: height } = document.documentElement;
	return {
		width,
		height,
	};
}

export function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = React.useState(getWindowSize());

	React.useEffect(() => {
		function onWindowResize() {
			setWindowSize(getWindowSize());
		}

		window.addEventListener('resize', onWindowResize);
		return () => window.removeEventListener('resize', onWindowResize);
	}, []);

	return windowSize;
}
