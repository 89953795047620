import React from 'react';
import { Spin } from 'antd';
import { Props } from './FileExportExtension';

const LazyFileExportExtension = React.lazy(() => import('./FileExportExtension'));

export const AsyncFileExportExtension: React.FC<Props> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyFileExportExtension {...props} />
		</React.Suspense>
	);
};
