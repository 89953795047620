import React from 'react';

export interface Stack<T> {
	push(item: T): void;
	pop(): T | undefined;
	peek(): T;
	toList(): T[];
}

export function useStack<T>(): Stack<T> {
	const [stack, setStack] = React.useState<T[]>([]);

	const push = React.useCallback((item: T) => {
		setStack(previousStack => [...previousStack, item]);
	}, []);

	const pop = React.useCallback((): T | undefined => {
		let result;
		setStack(previousStack => {
			result = previousStack.pop();
			return [...previousStack];
		});
		return result;
	}, []);

	const peek = React.useCallback((): T => {
		return stack[stack.length - 1];
	}, [stack]);

	const toList = React.useCallback((): T[] => {
		return [...stack];
	}, [stack]);

	return {
		push,
		pop,
		peek,
		toList,
	};
}
