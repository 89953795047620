import React from 'react';

export type UseResizerResult = {
	/** Reflects state of the useResizer hook - true if resizing is in progress (user is in the process of resizing the element being resized) */
	isResizing: boolean;
	/** When resizing towards the left, mouseDelta[0] is negative. When resizing upwards, mouseDelta[1] is negative */
	mouseDelta: number[];
	/** Attach this to an element as an onMouseDown handler to use that element as the resize initiator */
	initiateResizing: React.MouseEventHandler<HTMLDivElement>;
};

export function useResizer(onStartResizing: () => void): UseResizerResult {
	const [isResizing, setIsResizing] = React.useState(false);
	const [mouseStart, setMouseStart] = React.useState<number[]>([]);
	const [mouseDelta, setMouseDelta] = React.useState([0, 0]);

	const initiateResizing: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
		e => {
			document.body.style.cursor = 'col-resize';
			setIsResizing(true);
			setMouseStart([e.clientX, e.clientY]);
			setMouseDelta([0, 0]);
			onStartResizing();
		},
		[onStartResizing]
	);

	const mouseMoveHandler = React.useCallback(
		(e: MouseEvent) => {
			setMouseDelta([e.clientX - mouseStart[0], e.clientY - mouseStart[1]]);
		},
		[mouseStart]
	);

	const mouseUpHandler = React.useCallback(
		(e: MouseEvent) => {
			setMouseDelta([e.clientX - mouseStart[0], e.clientY - mouseStart[1]]);
			document.body.style.removeProperty('cursor');
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
			setIsResizing(false);
		},
		[mouseMoveHandler, mouseStart]
	);

	React.useEffect(() => {
		if (isResizing) {
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		} else {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		}
	}, [mouseMoveHandler, mouseUpHandler, isResizing]);

	return {
		isResizing,
		mouseDelta,
		initiateResizing,
	};
}
