import React from 'react';
import { LinkOutIcon } from '@sharefiledev/flow-web';
import { t } from '../util';

const TAG = '***TAG***';

export const createMessageComponentWithEmphasis = (
	translationId: string,
	attrName: string,
	attrValue: string
): React.ReactNode => {
	const splitMessage = t(translationId, { [attrName]: TAG }).split(TAG);
	return (
		<>
			{splitMessage[0]}
			<strong>
				{attrValue.length > 249 ? attrValue.substring(0, 249) + '...' : attrValue}
			</strong>
			{splitMessage[1]}
		</>
	) as React.ReactNode;
};

export const createMessageComponentAndReplaceAttribute = (
	translationId: string,
	attrName: string,
	attrValue: JSX.Element
): React.ReactNode => {
	const splitMessage = t(translationId, { [attrName]: TAG }).split(TAG);
	return (
		<>
			{splitMessage[0]}
			{attrValue}
			{splitMessage[1]}
		</>
	) as React.ReactNode;
};

/**
 * Detects any http OR https URLs and converts them to anchor elements
 * Assumes that if a URL is at the end of [value] or a sentence within [value], [value] or that sentence has no ending punctuation
 *
 * @param value The string to be parsed
 * @returns A constructed JSX Element with the matched URL strings converted to anchor elements
 */
export const detectAndInsertUrls = (value: string): JSX.Element => {
	let fragments = value.split(/((?:http:|https:)\/\/[a-zA-z0-9()@:%_\+.~#?&\/\/=-]{2,})/);

	return (
		<span>
			{fragments.map((fragment, index) => {
				if (fragment.startsWith('http://') || fragment.startsWith('https://')) {
					// [rel] added to prevent tabnabbing (ref: https://www.freecodecamp.org/news/how-to-use-html-to-open-link-in-new-tab/)
					return (
						<a key={index} href={fragment} target="_blank" rel="noopener noreferrer">
							<span style={{ paddingRight: '4px' }}>{fragment}</span>
							<LinkOutIcon
								size={14}
								alt={`(${t('workflows-pilet:messages.opens_in_new_tab')})`}
							/>
						</a>
					);
				} else {
					return <span key={index}>{fragment}</span>;
				}
			})}
		</span>
	);
};
