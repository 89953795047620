import {
	createRightSignatureClient,
	GetDocumentParameters,
	GetDocumentsResponse,
	GetFilledDocumentPreviewIdResponse,
	GetFilledDocumentPreviewUrlResponse,
	GetReusableTemplatesParameters,
	GetReusableTemplatesResponse,
	RightSignatureClient,
} from '@citrite/rightsignature-sdk';
import {
	GetFilledPreviewDocumentForReusableTemplateResponse,
	GetReusableTemplateResponse,
} from '@citrite/rightsignature-sdk/dist/typings/endpointTypes';
import { Contact, Prospect } from '@citrite/sf-api';

export class RightSignatureApiClientWrapper implements RightSignatureClient {
	private initializedClient: RightSignatureClient;

	public Initialize(dominoClient: RightSignatureClient) {
		this.initializedClient = dominoClient;
	}

	public getDocuments(params?: GetDocumentParameters): Promise<GetDocumentsResponse> {
		return this.initializedClient.getDocuments(params);
	}

	public getReusableTemplates(
		params?: GetReusableTemplatesParameters
	): Promise<GetReusableTemplatesResponse> {
		return this.initializedClient.getReusableTemplates(params);
	}

	public getReusableTemplate(templateId: string): Promise<GetReusableTemplateResponse> {
		return this.initializedClient.getReusableTemplate(templateId);
	}

	public getFilledPreviewDocumentForReusableTemplate(
		templateId: string,
		prospect: Prospect
	): Promise<GetFilledPreviewDocumentForReusableTemplateResponse> {
		return this.initializedClient.getFilledPreviewDocumentForReusableTemplate(
			templateId,
			prospect
		);
	}

	public getFilledDocumentPreviewId(
		templateId: string,
		contact: Prospect | Contact
	): Promise<GetFilledDocumentPreviewIdResponse> {
		return this.initializedClient.getFilledDocumentPreviewId(templateId, contact);
	}

	public getFilledDocumentPreviewUrl(
		documentPreviewId: string
	): Promise<GetFilledDocumentPreviewUrlResponse> {
		return this.initializedClient.getFilledDocumentPreviewUrl(documentPreviewId);
	}
}

export const rightSignatureApiClient: RightSignatureClient =
	new RightSignatureApiClientWrapper();

export function configureRightSignatureApiClient() {
	(rightSignatureApiClient as RightSignatureApiClientWrapper).Initialize(
		createRightSignatureClient({
			baseUrl: window.origin + '/io/esign/public/v2',
			useBff: true,
		})
	);
}
