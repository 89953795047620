import styled from '@emotion/styled';
import { Button } from '@sharefiledev/flow-web';

export const StartProspectFlowButton = styled(Button.Primary)`
	margin: 0px 0px 15px 0px;
	padding-right: 10px;

	div {
		line-height: 10px;
	}
`;

export const StyledSpanForFlowButton = styled.span`
	display: inline-flex;
`;
