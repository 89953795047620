import styled from '@emotion/styled';
import { fromThemeColors, ThemeColor, typography } from '@sharefiledev/flow-web';

export const MessageContainer = styled.span<{ background: string }>`
	display: flex;
	align-items: center;
	background: ${props => props.background};
	border-radius: 2px;
	box-sizing: border-box;
	padding: 4px 8px;
	color: ${fromThemeColors(ThemeColor.primaryText)};
	font-size: ${typography.sizes.sml};
	font-weight: ${typography.weights.semiBold};
	line-height: ${typography.sizes.base};
	gap: 8px;
`;

export const MessageIconContainer = styled.div({
	alignSelf: 'start',
	paddingTop: '3px',
});
