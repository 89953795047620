import { Contact } from '@sharefiledev/sharefile-appshell';

function getBaseAvatarUrl() {
	const hostnameParts = window.location.hostname.split('.');
	hostnameParts[0] = 'sf-assets';
	return `https://${hostnameParts.join('.')}/avatar`;
}

export function getAvatarUrl(contact: Contact) {
	return `${getBaseAvatarUrl()}/${contact.Id}`;
}
