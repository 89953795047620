import React from 'react';
import { Spin } from 'antd';
import { ContextualActionsProps, ContextualWorkflowListProps } from '../sdk';

const LazyContextualWorkflowList = React.lazy(() => import('./ContextualWorkflowList'));

export const AsyncContextualWorkflowList: React.FC<
	ContextualWorkflowListProps
> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyContextualWorkflowList {...props} />
		</React.Suspense>
	);
};

const LazyContextualActions = React.lazy(
	() => import('./contextual-actions/ContextualActions')
);

export const AsyncContextualActions: React.FC<ContextualActionsProps> = props => {
	return (
		<React.Suspense fallback={<Spin size="small" />}>
			<LazyContextualActions {...props} />
		</React.Suspense>
	);
};

const LazyAutomationsButton = React.lazy(() => import('./AutomationsButton'));

export const AsyncAutomationsButton: React.FC<{ containerRID?: string }> = props => {
	return (
		<React.Suspense fallback={<Spin size="small" />}>
			<LazyAutomationsButton {...props} />
		</React.Suspense>
	);
};
