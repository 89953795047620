import { PiletApi } from '@sharefiledev/sharefile-appshell';

export function getApiBaseUrl(app: PiletApi) {
	let apiOrigin = window.origin,
		suffix = 'workflows';

	try {
		if (!!window.origin.match(/.+\.sharefiletest\..+$/)) {
			suffix = 'workflows-dev-master';
		}
		if (typeof app.meta.config.apiOrigin === 'string') {
			apiOrigin = app.meta.config.apiOrigin;
		}
	} catch (_error) {
		// Do nothing (these conditions are only meaningful for test/dev environment)
	}

	return `${apiOrigin}${'/io/workflows'}/${suffix}`;
}

export function getApiBaseUrlNoBFF() {
	let suffix = 'workflows';
	let domain = 'sharefile';

	try {
		if (!!window.origin.match(/.+\.sharefiletest\..+$/)) {
			suffix = 'workflows-dev-master';
			domain = 'sharefiletest';
		} else if (!!window.origin.match(/.+\.sharefilestaging\..+$/)) {
			domain = 'sharefilestaging';
		}
	} catch (_error) {
		// Do nothing (these conditions are only meaningful for test/dev environment)
	}

	return `https://api.${domain}.io/${suffix}`;
}
