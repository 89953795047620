import React from 'react';
import { t } from '../../util';
import {
	StartClientFlowButton,
	StyledSpanForFlowButton,
} from './StartClientFlowAction.styled';

export function StartClientFlowAction(props: {
	onAction: () => void;
	actionName: string;
	inputs: any[];
}) {
	return (
		<StyledSpanForFlowButton
			title={
				props.inputs.length === 0
					? t('workflows-pilet:user_prompts.client_send_agreements_selection')
					: ''
			}
		>
			<StartClientFlowButton
				onClick={props.onAction}
				disabled={props.inputs.length === 0}
				data-analytics-id="clientListSendClientAgreementButton"
			>
				{props.actionName}
			</StartClientFlowButton>
		</StyledSpanForFlowButton>
	);
}
