import React from 'react';
import {
	hasScrolledIntoView as hasScrolledIntoViewHoc,
	InViewContext,
} from '@sharefiledev/flow-web';
import { NextPageTrigger } from './InfiniteScrollTrigger.styled';

interface ScrollProps extends InViewContext {
	loadNextPage: () => void;
}

const _InfiniteScroll: React.FC<ScrollProps> = ({
	loadNextPage,
	hasScrolledIntoView,
}) => {
	React.useEffect(() => {
		if (hasScrolledIntoView) {
			loadNextPage();
		}
	}, [hasScrolledIntoView, loadNextPage]);

	return <NextPageTrigger />;
};

export const InfiniteScrollTrigger = hasScrolledIntoViewHoc(_InfiniteScroll);
