import React from 'react';
import { AlertErrorIcon, ThemeColor, useThemeColor } from '@sharefiledev/flow-web';
import { SmallMessageBanner } from './SmallMessageBanner';

type ErrorMessageProps = {
	message: string | JSX.Element;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
	const errorBackgroundColor = useThemeColor(ThemeColor.errorBackground);
	return (
		<SmallMessageBanner
			background={errorBackgroundColor}
			iconElement={<AlertErrorIcon size={16} />}
			message={message}
		/>
	);
};
