import React from 'react';
import { XIcon } from '@sharefiledev/flow-web';
import { Property } from 'csstype';
import { withTooltip } from '../../util';
import { ChipContainer, ChipDelete } from './Chip.styled';
import { useChipColorPresets } from './ChipStyles';

export type ChipColors = {
	background: Property.Background<string | number>;
	hoverBackground: Property.Background<string | number>;
	selectedBackground: Property.Background<string | number>;
	textColor: Property.Color;
	deleteButton: {
		textColor: Property.Color;
		hoverTextColor: Property.Color;
	};
};

export type ChipProps = {
	/**
	 * Utilize React.useRef() in consumer to generate and pass in a ref if needed
	 */
	ref?: React.MutableRefObject<any>;
	label: string;
	isSelected: boolean;
	tooltip?: string;
	deleteTooltip?: string;
	onDelete: (e: React.MouseEvent<HTMLSpanElement>) => void;
	colors?: Partial<ChipColors>;
};

export const Chip = React.forwardRef<HTMLSpanElement, ChipProps>((props, ref) => {
	const chipColorPresets = useChipColorPresets();
	const colors: ChipColors = React.useMemo(
		() => ({
			...(chipColorPresets.default as ChipColors),
			...props.colors,
		}),
		[chipColorPresets, props.colors]
	);

	return (
		<ChipContainer
			ref={ref}
			colors={colors}
			isSelected={props.isSelected}
			aria-label={props.label}
			aria-details={props.tooltip}
		>
			{withTooltip(<span>{props.label}</span>, props.tooltip)}
			{withTooltip(
				<ChipDelete
					colors={colors}
					aria-label={props.deleteTooltip}
					role="button"
					onClick={props.onDelete}
				>
					<XIcon size={8} />
				</ChipDelete>,
				props.deleteTooltip
			)}
		</ChipContainer>
	);
});
