import { ResourceInfo } from '@citrite/domino-core-sdk/dist/types';
import { checkLDFlags } from '../provisioning/helpers';
import { FeatureFlag } from './featureFlagDefinitions';

export const RESOURCE_FLAGS = new Map<string, FeatureFlag[]>([
	['sendEmail', [FeatureFlag.EnableSendEmailForWorkflowDesigner]],
]);

export const isResourceEnabled = (resource: ResourceInfo): boolean => {
	return RESOURCE_FLAGS?.has(resource.name)
		? checkLDFlags(RESOURCE_FLAGS.get(resource.name))
		: true;
};
