import { FetchResourceFn } from '../resourceRegistryClient';

export function withFetchResourceOverride(
	fn: FetchResourceFn,
	override?: FetchResourceFn
): FetchResourceFn {
	return async (fqn: string) => {
		if (!!override) {
			try {
				return await override(fqn);
			} catch (error) {
				return await fn(fqn);
			}
		}
		return await fn(fqn);
	};
}
