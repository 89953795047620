import React, { lazy, Suspense } from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockParams } from '@sharefiledev/solution-view-engine';
import { Spin } from 'antd';
import { LaunchFlowBlockParams, WorkflowVisualizationParams } from '../sdk';

export type LaunchFlowBlockProps = LaunchFlowBlockParams &
	BlockParams & { piletApi: PiletApi };

const LazyLaunchFlowButton = lazy(() => import('./LaunchFlowButton'));
export const LaunchFlowButton = (props: LaunchFlowBlockProps) => (
	<Suspense fallback={<Spin />}>
		<LazyLaunchFlowButton {...props} />
	</Suspense>
);

export type WorkflowVisualizationProps = WorkflowVisualizationParams & BlockParams;

const LazyWorkflowVisualization = React.lazy(() => import('./WorkflowVisualization'));
export const AsyncWorkflowVisualization: React.FC<WorkflowVisualizationProps> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyWorkflowVisualization {...props} />
		</React.Suspense>
	);
};
