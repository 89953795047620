import React from 'react';
import { Flex, Spin } from 'antd';
import { DashboardPageProps } from './DashboardPage';

const LazyDashboardPage = React.lazy(() => import('./DashboardPage'));
export const AsyncDashboardPage: React.FC<DashboardPageProps> = props => (
	<React.Suspense
		fallback={
			<Flex justify="center" align="center" style={{ height: '100%' }}>
				<Spin />
			</Flex>
		}
	>
		<LazyDashboardPage {...props} />
	</React.Suspense>
);
