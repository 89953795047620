import { AccountPreferencesWithDominoAST, AST } from '../types';

export function checkASTs(preferences: AccountPreferencesWithDominoAST, asts: AST[]) {
	if (!!!asts) {
		return true;
	}
	return asts.reduce((acc, ast) => {
		return acc && !!preferences[ast];
	}, true);
}
