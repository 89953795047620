import { Resource, ResourceInfo } from '@citrite/domino-core-sdk';
import * as Builder from '@sharefile/domino-builder';
import { parseResourceName } from '@sharefile/solutions-platform-common';
import { dominoApiClient } from '../dominoApiClient';
import { withFetchResourceOverride } from './helpers';

export interface ResourceRegistry extends Builder.ResourceRegistryClient {
	fetchResources(type: string): Promise<ResourceInfo[]>;
	fetchResource(fqn: string): Promise<Resource>;
}

export type FetchResourceFn = (fqn: string) => Promise<Resource>;

export function getResourceRegistryClient(override?: FetchResourceFn): ResourceRegistry {
	const directiveWhitelist: Map<String, Resource> = new Map(
		[
			{
				name: 'assign',
				definition: `
action:
  name: assign
  label: Assign
  description: Sets values of needed variables for the flow
  params: []
`,
			},
			{
				name: 'callback',
				definition: `
action:
  name: callback
  label: Callback
  description: Provides values to the given source
  params: []
`,
			},
			{
				name: 'http',
				definition: `
action:
  name: http
  label: Http
  description: Makes a network request to an external URL
  params: []
`,
			},
			{
				name: 'stage',
				definition: `
action:
  name: stage
  label: Stage
  params: []
`, // decsription omitted; intent is to be user-defined via label
			},
			{
				name: 'wait',
				definition: `
action:
  name: wait
  label: Wait
  description: Pauses execution of the flow for a given amount of time
  params: []
`,
			},
		].map(({ name, definition }) => [
			name,
			{
				definition,
				type: '',
				namespace: '',
				package: '',
				name: '',
				label: '',
				description: '',
				fqn: '',
				version: '',
				category: '',
			},
		])
	);

	const fetchResource = withFetchResourceOverride(
		async (fqn: string): Promise<Resource> => {
			const resourceName = parseResourceName(fqn);

			if (typeof resourceName === 'string') {
				return (
					directiveWhitelist.get(resourceName) ?? {
						definition: `
action:
  name: fallback
  label: Unknown action
  params: []
`,
						type: '',
						namespace: '',
						package: '',
						name: '',
						label: '',
						description: '',
						fqn: '',
						version: '',
						category: '',
					}
				);
			}

			return await dominoApiClient.resources.get({
				namespace: resourceName.namespace,
				_package: resourceName.package,
				name: resourceName.name,
				version: resourceName.version,
			});
		},
		override
	);

	const fetchResources = async (type: string): Promise<ResourceInfo[]> =>
		await dominoApiClient.resources.listResources({
			type,
		});

	return {
		fetchResources,
		fetchResource,
	};
}

export enum ResourceType {
	Action = 'action',
	Trigger = 'trigger',
	Type = 'type',
}
