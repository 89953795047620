import React from 'react';
import { Tooltip } from 'antd';

export function withTooltip(component: JSX.Element, tooltipText: string): JSX.Element {
	return (
		<Tooltip title={tooltipText ?? ''}>
			<>{component}</>
		</Tooltip>
	);
}
