import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { FormProps, workflowsPiletExtensionSlots } from '../sdk';
import { FormExtensionProps } from './FormExtension';

const formSlots = [workflowsPiletExtensionSlots.dominoFormRendererSlot];

const LazyFormExtension = React.lazy(() => import('./FormExtension'));

const AsyncFormExtension: React.FC<FormExtensionProps> = props => {
	return (
		<React.Suspense fallback={<Spin />}>
			<LazyFormExtension {...props} />
		</React.Suspense>
	);
};

export function configureFormExtensions(app: PiletApi) {
	formSlots.forEach(slot => {
		app.registerExtension(slot, ({ params }: { params: FormProps }) => {
			return <AsyncFormExtension piletApi={app} {...params} />;
		});
	});
}
