import {
	palette,
	sharefilePalette,
	ThemeColor,
	useThemeColor,
	useThemePalette,
} from '@sharefiledev/flow-web';
import { ChipColors } from './Chip';

export type ChipColorPresetTypes =
	| 'symbol'
	| 'operator'
	| 'function'
	| 'error'
	| 'default';

export const useChipColorPresets: () => Record<
	ChipColorPresetTypes,
	Partial<ChipColors>
> = () => {
	const textColor = useThemeColor(ThemeColor.primaryText);
	const hoverTextColor = useThemeColor(ThemeColor.secondaryActionIcon);
	const infoBackgroundColor = useThemeColor(ThemeColor.infoBackground);
	const successBackgroundColor = useThemeColor(ThemeColor.successBackground);
	const warningBackgroundColor = useThemeColor(ThemeColor.warningBackground);
	const errorBackgroundColor = useThemeColor(ThemeColor.errorBackground);

	const symbolHoverBackground = useThemePalette({
		neutral: palette.blue500,
		sharefilebrand: sharefilePalette.blue200,
	});
	const symbolSelectedBackground = useThemePalette({
		neutral: palette.blue600,
		sharefilebrand: sharefilePalette.blue100,
	});
	const operatorHoverBackground = useThemePalette({
		neutral: palette.green500,
		sharefilebrand: sharefilePalette.green200,
	});
	const operatorSelectedBackground = useThemePalette({
		neutral: palette.green600,
		sharefilebrand: sharefilePalette.green100,
	});
	const functionHoverBackground = useThemePalette({
		neutral: palette.orange500,
		sharefilebrand: sharefilePalette.orange200,
	});
	const functionSelectedBackground = useThemePalette({
		neutral: palette.orange600,
		sharefilebrand: sharefilePalette.orange100,
	});
	const errorHoverBackground = useThemePalette({
		neutral: palette.red500,
		sharefilebrand: sharefilePalette.red200,
	});
	const errorSelectedBackground = useThemePalette({
		neutral: palette.red600,
		sharefilebrand: sharefilePalette.red100,
	});
	const defaultHoverBackground = useThemePalette({
		neutral: palette.grey200,
		sharefilebrand: sharefilePalette.greylight300,
	});
	const defaultSelectedBackground = useThemePalette({
		neutral: palette.grey200,
		sharefilebrand: sharefilePalette.greylight200,
	});

	const defaultBackground = useThemePalette({
		neutral: palette.grey100,
		sharefilebrand: sharefilePalette.greylight200,
	});

	return {
		symbol: {
			background: infoBackgroundColor,
			textColor,
			hoverBackground: symbolHoverBackground,
			selectedBackground: symbolSelectedBackground,
		},
		operator: {
			background: successBackgroundColor,
			textColor,
			hoverBackground: operatorHoverBackground,
			selectedBackground: operatorSelectedBackground,
		},
		function: {
			background: warningBackgroundColor,
			textColor,
			hoverBackground: functionHoverBackground,
			selectedBackground: functionSelectedBackground,
		},
		error: {
			background: errorBackgroundColor,
			textColor,
			hoverBackground: errorHoverBackground,
			selectedBackground: errorSelectedBackground,
		},
		default: {
			background: defaultBackground,
			hoverBackground: defaultHoverBackground,
			selectedBackground: defaultSelectedBackground,
			textColor,
			deleteButton: {
				textColor,
				hoverTextColor,
			},
		},
	};
};
