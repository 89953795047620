import React from 'react';
import { ThemeColor, useThemeColor, WarningIcon } from '@sharefiledev/flow-web';
import { SmallMessageBanner } from './SmallMessageBanner';

type WarningMessageProps = {
	message: string | JSX.Element;
};

export const WarningMessage: React.FC<WarningMessageProps> = ({ message }) => {
	const warningBackgroundColor = useThemeColor(ThemeColor.warningBackground);
	return (
		<SmallMessageBanner
			message={message}
			iconElement={<WarningIcon size={16} />}
			background={warningBackgroundColor}
		/>
	);
};
