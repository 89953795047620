import styled from '@emotion/styled';
import { fromThemeColors, ThemeColor } from '@sharefiledev/flow-web';

export const ContentPane = styled.div({
	padding: 20,
});

export const DropdownItemWithTopSeperator = styled.div`
	border-top: 1px solid ${fromThemeColors(ThemeColor.inputBorder)};
`;

export const CenterChild = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
});
