import { getFeatureFlagValue } from '@citrite/feature-flags';
import { AccountPreferences } from '@citrite/sf-api';
import { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { Entitlement } from '../entitlements';
import { FeatureFlag } from '../FeatureFlags';

type AccountPreferencesWithIntegrationsAST = AccountPreferences & {
	EnableThirdPartyIntegrations?: boolean;
};
type AST = keyof AccountPreferencesWithIntegrationsAST;
const requiredASTs = ['EnableThirdPartyIntegrations'] as AST[];

export function shouldDisplayIntegrations(app: PiletApi) {
	try {
		const userContext = app.sf.piletUserContext.get();

		if (getFeatureFlagValue(FeatureFlag.EnableProductLedGrowth)) {
			const { entitlements }: PiletUserContext = userContext;
			return !!entitlements.getEntitlementStatus(Entitlement.IntegrationsInstall);
		} else {
			const preferences = userContext.account.Preferences;
			return checkASTs(preferences, requiredASTs);
		}
	} catch (err) {
		return false;
	}
}

export function checkASTs(
	preferences: AccountPreferencesWithIntegrationsAST,
	asts: AST[]
) {
	if (!!!asts) {
		return true;
	}
	return asts.reduce((acc, ast) => {
		return acc && !!preferences[ast];
	}, true);
}
