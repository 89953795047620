import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { shouldDisplayIntegrations } from '../checkIntegrationsAST';
import { ExtensionType } from '../types';
import { AsyncFileExportExtension } from '.';

export const configureFileExportAppShellExtension = (app: PiletApi) => {
	if (shouldDisplayIntegrations(app)) {
		const { account } = app.sf.piletUserContext.get();
		app.registerExtension(
			'urn:sfextslot:sharefile-appshell:files:context-bar:export-file-button',
			({ params }) => {
				const files = params.files.map(file => ({
					name: file.fileName,
					rid: file.shareFileStreamId,
				}));

				return (
					<AsyncFileExportExtension
						account={account}
						files={files}
						extensionType={ExtensionType.APPSHELL}
					/>
				);
			}
		);
	}
};
