import React from 'react';
import { t } from '../../util';
import {
	StartProspectFlowButton,
	StyledSpanForFlowButton,
} from './StartProspectFlowAction.styled';

export function StartProspectFlowAction(props: {
	onAction: () => void;
	actionName: string;
	inputs: any[];
}) {
	return (
		<StyledSpanForFlowButton
			title={
				props.inputs.length === 0
					? t('workflows-pilet:user_prompts.prospect_send_agreements_selection')
					: ''
			}
		>
			<StartProspectFlowButton
				onClick={props.onAction}
				disabled={props.inputs.length === 0}
				data-analytics-id="prospectListSendClientAgreementButton"
			>
				{props.actionName}
			</StartProspectFlowButton>
		</StyledSpanForFlowButton>
	);
}
