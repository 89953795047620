import React from 'react';
import { FlowInfo } from '@citrite/domino-core-sdk';
import { css } from '@emotion/css';
import {
	Button,
	Dropdown,
	LoadingPrimary,
	Tooltip,
	WorkflowsIcon,
} from '@sharefiledev/flow-web';
import { useQuery } from '@tanstack/react-query';
import { dominoApiClient } from '../../data/clients';
import { t } from '../../util';
import {
	ButtonIcon,
	FloatingAction,
	FloatingActionButton,
	FloatingActionWrapper,
	WorkflowOption,
} from './LaunchFlowAction.styled';

export function LaunchFlowAction(props: {
	onAction: (flowId: string) => void;
	actionName: string;
	extensionSlotName: string;
}) {
	const {
		isLoading: isLoadingOptions,
		error: errorLoadingOptions,
		data: scopedWorkflows,
	} = useQuery(
		['flowsList', props.extensionSlotName],
		() =>
			dominoApiClient.getFlows({
				limit: 100,
				triggerName: props.extensionSlotName,
				excludeInactive: true,
				paginate: false,
			}) as unknown as FlowInfo[]
	);

	const availableOptions = isLoadingOptions
		? [{ children: <LoadingPrimary /> }]
		: errorLoadingOptions || !scopedWorkflows.length
		? [{ children: <span>{t('workflows-pilet:messages.no_workflows')}</span> }]
		: scopedWorkflows
				?.map(flow => {
					return {
						children: (
							<Tooltip hoverTrigger={<WorkflowOption>{flow.name}</WorkflowOption>}>
								{flow.name}
							</Tooltip>
						),
						isAvailable: true,
						onClick: () => props.onAction(flow.id),
					};
				})
				.slice(0, 15);

	return (
		<span className={css(FloatingAction)}>
			<Dropdown options={availableOptions}>
				{buttonProps => (
					<Button.Wrapper {...buttonProps} className={css(FloatingActionButton)}>
						<FloatingActionWrapper>
							<WorkflowsIcon
								id="startWorkflowIcon"
								color="#ffffff"
								className={css(ButtonIcon)}
								size={16}
								title="Automated Workflows"
							/>
						</FloatingActionWrapper>
					</Button.Wrapper>
				)}
			</Dropdown>
		</span>
	);
}
