import styled from '@emotion/styled';

export const NextPageTrigger = styled.div(() => ({
	// Ensure it is visible
	minHeight: '1px',
	// Display behind main content
	zIndex: -1,
	// Shift up to trigger slightly earlier load
	position: 'relative',
	top: '-115px',
}));
